body {
    background-color: var(--main-bg);
    color: var(--main-txt);
    text-align: center;
    overflow-x: hidden;
}

.app {
    margin: 0 auto;
    max-width: 1080px;
    text-align: left;
}

.link, .link:hover, .link:active, .link:visited {
    color: #ffffff;
}

nav {
    height: fit-content;
    transition: all 0.1s ease-in-out;
    position: fixed;
    width: 100%;
    margin: 0px 0px;
    border-radius: 0;
    padding: 15px 30px;
    border-bottom: #414141 solid 1px;
    background-color: rgba(0,0,0,0.70);
    backdrop-filter: saturate(120%) blur(18px);
    text-align: center;
    z-index: 1;
    box-shadow: 0px 20px 30px 10px rgba(0, 0, 0, 0.6);
}

.center {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 1080px;
    text-align: left;
}

nav .center img {
    height: 50px;
}

nav .center h1 {
    font-size: 1.8rem;
    font-weight: 600;
}

section {
    padding: 10px 20px;
    margin-bottom: 150px;
    /* animation: fadeIn 0.5s ease-in-out; */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

section h1 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.5rem;
    margin-bottom: 10px;
}

section.top {
    padding-top: 150px;
    margin-bottom: 100px;
}

h1.title {
    margin-bottom: 20px;
}

h1 sup, sub{
    font-weight: 700;
    font-size: 0.6em;
    color: var(--sub-txt);
}

span.light {
    font-size: 1.9rem;
    font-weight: 800;
    line-height: 2.5rem;
    color: #222;
    white-space: nowrap;
}

span.light::before {
    content: attr(data-text);
    position: absolute;
    color: var(--main-txt);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    animation: animate 10s ease-out 1;
    filter: drop-shadow(0 0 10px var(--main-txt)) drop-shadow(0 0 100px var(--main-txt));
}

@keyframes animate {
    0%, 10% {
        width: 0;
    }
    70%, 90% {
        width: 100%;
    }
}

section.new {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    margin-bottom: 100px;
    vertical-align: middle;
}

.right img {
    height: 260px;
    border-radius: 10px;
}

.right {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

section.new button {
    display: flex;
    border: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: var(--main-txt);
    vertical-align: middle;
    gap: 3px;
    transition: 0.3s;
    cursor: pointer;
}

section.new button:hover, section.new button:active {
    gap: 20px;
}

section p {
    margin-bottom: 10px;
    font-size: 1em;
    line-height: 1.5em;
}

p.intro {
    font-size: 1.2em;
    line-height: 1.5em;
    color: var(--sub-txt);
}

.award {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px 10px;
    border: #faa515 solid 2px;
    gap: 20px;
    font-size: 1.1rem;
    margin-bottom: 10px;
    line-height: 1.4rem;
}

.both {
    display: flex;
    gap: 30px;
}

a {
    color: #166fec;
}

a:hover {
    color: #305bff;
}

svg {
    width: 2.5rem;
    height: 2.5rem;
}

.plat {
    color: #ffffff;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.instagram {
    background-image: linear-gradient(72.44deg, #ff7a00 11.92%, #ff0169 51.56%, #d300c5 85.69%);
}

.discord {
    background-color: #5865f2;
}

.tistory {
    background-color: #ffffff;
}

.email {
    background-color: #ef4444;
}

.blog {
    background-image: url('../../public/images/contact/exon.jpg');
    background-size: cover;
}

.github {
    background-color: #0a0a0a;
}

.contact {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
}

.contact .user {
    display: flex;
    flex-direction: column;
}

.user .platform {
    font-size: 0.8rem;
    color: var(--sub-txt);
}

.contactList {
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px; */
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

p.white {
    font-size: 1.2em;
	color: var(--sub-txt);
    line-height: 1.5em;
	animation: whiteAnimation 5s ease-out;
}

@keyframes whiteAnimation {
	0% {
		color: #ffffff;
		filter: drop-shadow(0 0 10px var(--main-txt)) drop-shadow(0 0 10px var(--main-txt));
	}
	100% {
		color: var(--sub-txt);
	}
}

@media (width <= 1050px) {
    nav {
        position: fixed;
        width: 100%;
        margin: 0px 0px;
        border-radius: 0;
        padding: 15px 30px;
        border: none;
        border-bottom: #414141 solid 1px;
        box-shadow: 0px 20px 30px 10px rgba(0, 0, 0, 0.6);
        background-color: rgba(0,0,0,0.70);
        backdrop-filter: saturate(120%) blur(18px);
    }

    .both {
        gap: 10px;
        flex-direction: column;
    }

    .both .right {
        text-align: center;
        width: 100%;
        overflow: auto;
    }

    .both .right img:first-child:nth-last-child(1) {
        height: auto;
        width: 100%;
    }

    .contactList {
        flex-direction: column;
        gap: 10px;
    }

    
}
/* .logos h1 {
    position: absolute;
    text-align: center;
}

.logos {
    max-width: 720px;
    min-width: 720px;
    width: 720px;
    display: flex;
    align-items: center;
    text-align: center;
    padding-top: 100px;
}

.logos img {
    vertical-align: middle;
    height: 200px;
}

.logos img.logo1 {
    animation-name: logo1;
    animation-duration: 2s;
}

.logos img.logo2 {
    animation-name: logo2;
    animation-duration: 2s;
}

@keyframes logo1 {
    0% {
        transform: translateX(200px)
    }
    100% {
        transform: translateX(0px)
    }
}

@keyframes logo2 {
    0% {
        transform: translateX(-200px)
    }
    100% {
        transform: translateX(0px)
    }
} */