@import 'reset.css';

body {
    --main-bg: #000000;
    --main-txt: #ffffff;
    --sub-txt: #b0b8c1;

    font-family: 'Pretendard', sans-serif !important;

    background-color: var(--light-main-bg);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

::selection {
    background-color: var(--sub-txt);
    color: #000000;
}

strong {
    font-weight: 700;
}

li {
    text-size-adjust: 100%;
    list-style: disc;
    font-size: calc(10px + 0.7vmin);
}

li::marker {
    color: var(--light-main-txt);
}

h1 {
    font-size: 2rem;
    font-weight: 600;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

